<template>
  <div class="d-flex flex-column">
    <b-table
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :responsive="true"
      table-class="table-head-custom table-vertical-center"
      show-empty
      hover
    >
      <!-- begin:header template    -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">
          {{ data.index + 1 }}
        </span>
      </template>

      <template v-slot:cell(employee)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template v-slot:cell(actions)="data">
        <button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="$emit('showIndividualAttendance', data)"
          v-b-tooltip.hover="$t('ATTENDANCE.HOVER.YEARLY')"
        >
          <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
            <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
          </span>
        </button>
      </template>

      <template v-slot:cell(present)="data">
        <AppSmallLightButton>
          {{ data.item.attendance.present_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(absent)="data">
        <AppSmallLightButton>
          {{ data.item.attendance.absent_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(leave)="data">
        <AppSmallLightButton
          @button-clicked="$emit('leave-count-clicked', data.item)"
        >
          {{ data.item.attendance.leave_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(away)="data">
        <AppSmallLightButton
          @button-clicked="$emit('away-count-clicked', data.item)"
        >
          {{ data.item.attendance.away_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(holiday)="data">
        <AppSmallLightButton>
          {{ data.item.attendance.holiday_count }}
        </AppSmallLightButton>
      </template>

      <template v-slot:cell(offdays)="data">
        <AppSmallLightButton>
          {{ data.item.attendance.offdays_count }}
        </AppSmallLightButton>
      </template>

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No employee registered"
          text-bottom="To add employees, go to Registration section"
        ></AppEmptyList>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-monthly-attendance-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppSmallLightButton from "@/modules/core/components/button/AppSmallLightButton";
export default {
  name: "EmployeeYearlyAttendanceTable",
  components: {
    AppSmallLightButton,
    AppLoader,
    CommonEmployeeSummary,
    AppEmptyList,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: Array,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "present", label: this.$t("EDUATTENDANCE.GENERAL.PRESENT") },
        { key: "absent", label: this.$t("EDUATTENDANCE.GENERAL.ABSENT") },
        { key: "leave", label: this.$t("EDUATTENDANCE.GENERAL.ONLEAVE") },
        { key: "away", label: this.$t("EDUATTENDANCE.GENERAL.AWAY") },
        { key: "holiday", label: this.$t("EDUATTENDANCE.GENERAL.HOLIDAY") },
        { key: "offdays", label: this.$t("EDUATTENDANCE.GENERAL.OFFDAY") },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
    };
  },
};
</script>

<style scoped></style>
