<template>
  <div>
    <div class="d-flex justify-content-end align-content-center">
      <div class="my-auto">
        <b-button
          class="font-weight-bolder"
          variant="light-primary"
          @click="$router.push({ name: 'teacherYearlyAttendanceExport' })"
          >Details
        </b-button>
      </div>
    </div>

    <EmployeeYearlyAttendanceTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusyStore"
      @changePage="handlePageChange"
      @showIndividualAttendance="goToYearlyIndividual"
    ></EmployeeYearlyAttendanceTable>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeeYearlyAttendanceTable from "@/modules/school/components/shared/management/attendance/table/EmployeeYearlyAttendanceTable";
export default {
  name: "TeacherYearlyAttendance",
  components: { EmployeeYearlyAttendanceTable },
  data() {
    return {
      isBusy: false,
      employee: {},
      date: new Date(dayjs().format()),
      year: dayjs().year(),
      attendance: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherYearlyAttendanceList")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setTeacherYearlyAttendancePage", page);
      this.fetch();
    },

    filterDate(data) {
      console.log("Date ", data);
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
      });

      this.year = data.year;

      this.date = date.format();

      this.$store.dispatch("changeTeacherYearlyAttendanceListDate", {
        date: date.format(),
      });

      this.fetch();
    },

    goToYearlyIndividual(data) {
      console.log(data);
      this.$router.push({
        name: "individualTeacherYearlyAttendance",
        query: {
          id: data.item.id,
          name: data.item.name,
          year: this.year,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherYearlyAttendanceList",
      pagination: "getTeacherYearlyAttendancePagination",
      isBusyStore: "getTeacherYearlyAttendanceListBusy",
    }),
  },
};
</script>

<style scoped></style>
